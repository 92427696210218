import { apis } from '@doltech/core/lib/api/api.config';
import { useQuery } from 'react-query';

export const fetchResourceStatus = async (query) => {
  const { data } = await apis.publicOnlineTestV2.get('GET_RESOURCE_STATUS', {
    id: query.id,
    path: query.type,
  });
  return data;
};

export const useFetchResourceStatus = (query) =>
  useQuery(['GET_RESOURCE_STATUS', query.id], () => fetchResourceStatus(query), {
    enabled: !query.status && Boolean(query.id),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnMount: true,
    retry: false,
    keepPreviousData: false,
  });
