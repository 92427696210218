import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M2.95 4.933a1 1 0 011-1.732l1.3.75a1 1 0 11-1 1.732l-1.3-.75z" /><path fill="currentColor" fillRule="evenodd" d="M18.5 9.947c0-3.906-3.317-7.077-7.283-6.6-2.863.345-5.199 2.66-5.636 5.545-.402 2.65.74 5.078 2.661 6.472a.1.1 0 01.009.007v2.577c0 1.826 1.448 3.352 3.291 3.352h.917c1.843 0 3.292-1.526 3.292-3.352v-2.576l.002-.002.004-.003a6.679 6.679 0 002.744-5.42zm-7.044-4.614c2.703-.325 5.045 1.834 5.045 4.614l-8.943-.755c.307-2.023 1.955-3.625 3.898-3.859zM16.5 9.947a4.68 4.68 0 01-1.919 3.8 2.02 2.02 0 00-.753 1.053H10.17a2.027 2.027 0 00-.753-1.054c-1.335-.968-2.146-2.665-1.86-4.554l8.943.755zM13.75 16.8h-3.5v1.148c0 .772.602 1.352 1.291 1.352h.917c.689 0 1.292-.58 1.292-1.352V16.8z" clipRule="evenodd" /><path fill="currentColor" d="M19.5 10a1 1 0 011-1H22a1 1 0 110 2h-1.5a1 1 0 01-1-1zM2 9a1 1 0 100 2h1.5a1 1 0 100-2H2zm.585 7.433a1 1 0 01.366-1.366l1.299-.75a1 1 0 111 1.732l-1.3.75a1 1 0 01-1.365-.366zm18.83 0a1 1 0 00-.366-1.366l-1.299-.75a1 1 0 10-1 1.732l1.3.75a1 1 0 001.365-.366zm-.365-11.5a1 1 0 00-1-1.732l-1.3.75a1 1 0 001 1.732l1.3-.75z" /></svg>;
});
export const IconSystemV2SpecificBulb2 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));