import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C2.44728 7 2 7.44728 2 8V17C2 17.5527 2.44728 18 3 18H12C12.5527 18 13 17.5527 13 17V8C13 7.44728 12.5527 7 12 7H3ZM0 8C0 6.34272 1.34272 5 3 5H12C13.6573 5 15 6.34272 15 8V17C15 18.6573 13.6573 20 12 20H3C1.34272 20 0 18.6573 0 17V8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.873 10.3278C11.2636 10.7183 11.2637 11.3514 10.8732 11.742L7.41621 15.2C7.22868 15.3876 6.97431 15.493 6.70907 15.493C6.44383 15.493 6.18945 15.3877 6.00189 15.2001L4.41789 13.6161C4.02737 13.2256 4.02737 12.5924 4.41789 12.2019C4.80842 11.8114 5.44158 11.8114 5.83211 12.2019L6.7089 13.0787L9.45879 10.328C9.84926 9.93741 10.4824 9.93732 10.873 10.3278Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2C6.44728 2 6 2.44728 6 3V6C6 6.55228 5.55228 7 5 7C4.44772 7 4 6.55228 4 6V3C4 1.34272 5.34272 0 7 0H17C18.6573 0 20 1.34272 20 3V13C20 14.6573 18.6573 16 17 16H14C13.4477 16 13 15.5523 13 15C13 14.4477 13.4477 14 14 14H17C17.5527 14 18 13.5527 18 13V3C18 2.44728 17.5527 2 17 2H7Z"
        fill="currentColor"
      />
    </svg>
  );
});
export const IconSystemV2InterfaceEssentialAnswerCheck2 = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
