import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M5.497 3.996a1 1 0 00-1 1.001v11.176c.313-.11.65-.17 1-.17h13.006a1 1 0 001-1.002V4.997a1 1 0 00-1-1H5.497zm16.007 1.001a3 3 0 00-3.001-3H5.497a3 3 0 00-3 3v14.006a3 3 0 003 3h13.006a3 3 0 003-3V4.997zm-2 12.834c-.313.11-.65.171-1.001.171H5.497a1 1 0 100 2.002h13.006a1 1 0 001-1.001V17.83z" clipRule="evenodd" /><path fill="currentColor" fillRule="evenodd" d="M8.668 11.802a1 1 0 011-1h4.547a1 1 0 010 2H9.668a1 1 0 01-1-1z" clipRule="evenodd" /><path fill="currentColor" fillRule="evenodd" d="M11.943 6a1 1 0 01.904.572l2.842 6.003a1 1 0 01-1.807.855l-1.939-4.094-1.94 4.095a1 1 0 01-1.807-.857l2.844-6.002A1 1 0 0111.943 6z" clipRule="evenodd" /></svg>;
});
export const IconSystemV2CourseTestVocabulary = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));