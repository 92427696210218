import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M17.5 4.205A9 9 0 005.206 7.5L2.543 6.063a.5.5 0 00-.718.577l1.538 5.38a.5.5 0 00.618.343l5.367-1.533a.5.5 0 00.107-.917L6.938 8.499A6.995 6.995 0 0116.5 5.937a6.995 6.995 0 012.562 9.562 6.995 6.995 0 01-11.732.6c-.284-.396-.782-.605-1.248-.459-.6.188-.893.871-.542 1.393A9 9 0 1017.5 4.205z"
      />
    </svg>
  );
});
export const IconCourseRefresh = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
